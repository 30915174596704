import { DialogBoardModeComponent } from './views/dialog-board-mode/dialog-board-mode.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { BulkLoadComponent } from './views/bulk-load/bulk-load.component';

import { BoardComponent } from './views/board/board.component';
import { LanguagesComponent } from './views/languages/languages.component';
import { NewlanguageComponent } from './views/newlanguage/newlanguage.component';
import { ViewAuthUserBoardComponent } from './views/viewAuthUserBoard/view-auth-user-board/view-auth-user-board.component' 
import { BudgetreportComponent } from './views/budgetreport/budgetreport.component';
import { MarketComponent } from './views/users/components/market/market.component';
import { RecordBalanceSheetComponent } from './views/record-balance-sheet/record-balance-sheet.component';
import { RecordIncomeAndBalanceComponent } from './views/record-income-and-balance/record-income-and-balance.component';
import { BoardImageComponent } from './views/board-image/board-image.component';
import { BoardServiceComponent } from './views/board-service/board-service.component';
import { BoardImageServiceComponent } from './views/board-image-service/board-image-service.component';
import { BoardProfesionalSSComponent } from './views/board-profesional-ss/board-profesional-ss.component';
import { BoardProfesionalSsRentComponent } from './views/board-profesional-ss-rent/board-profesional-ss-rent.component';

const routes: Routes = [
  /*{path:'new-language', component: NewlanguageComponent},
  {path:'board', component: BoardComponent},
  {path:'login', component: LoginComponent},
  {path:'updatePassword', component: ForgotPasswordComponent,canActivate: [AuthGuardService]},
  {path:'listado-usuarios', component: ListadoUsuariosComponent,canActivate: [AuthGuardService]},
  {path:'create-user/:type',component: CapturaUsuarioComponent,canActivate: [AuthGuardService]},
  {path: 'edit-contact-distributor/:distributorID/:companyName/:userID', component: CapturaDistribuidorComponent,canActivate: [AuthGuardService]},
  {path:'create-contact-distributor',component: CreateContactDistributorComponent,canActivate: [AuthGuardService]},
  {path:'edit-contact-distributor/:userID',component: CreateContactDistributorComponent,canActivate: [AuthGuardService]},
  {path:'languages',component: LanguagesComponent,canActivate: [AuthGuardService]},
  {path: 'edit-user/:userId', component: CapturaUsuarioComponent,canActivate: [AuthGuardService]},
  {path: 'new-distributor', component: CapturaDistribuidorComponent,canActivate: [AuthGuardService]},
  {path: 'create-workshop/:workshopID', component: NewWorkshopComponent,canActivate: [AuthGuardService]},
  {path: 'workshop', component: WorkshopsComponent,canActivate: [AuthGuardService]},
  {path: 'bulk-load', component: BulkLoadComponent,canActivate: [AuthGuardService]},
  {path: 'new-observers', component: NewObserverComponent,canActivate: [AuthGuardService]},
  {path: 'edit-observers/:workshopID', component: NewObserverComponent,canActivate: [AuthGuardService]},
  {path: 'edit-distributor/:distributorID/:companyName', component: CapturaDistribuidorComponent,canActivate: [AuthGuardService]},
  {path:'**', pathMatch:'full', redirectTo:'login' }*/
  {path:'new-language', component: NewlanguageComponent,canActivate: [AuthGuardService]},
  {path:'budget/:workshopId', component: BudgetreportComponent},
  {path:'play/board/:workshopID/:userMail', component: BoardComponent},
  {path:'play/boardservices/:workshopID/:userMail', component: BoardServiceComponent},
  {path:'play/boardprofessionalservices/:workshopID/:userMail', component: BoardProfesionalSSComponent},
  {path:'play/boardprofessionalservicesrent/:workshopID/:userMail', component: BoardProfesionalSsRentComponent},
  {path:'ViewAuthUserBoardComponent', component: ViewAuthUserBoardComponent},
  /*{path:'login', component: LoginComponent},
  {path:'updatePassword', component: ForgotPasswordComponent},*/
  {path:'languages',component: LanguagesComponent,canActivate: [AuthGuardService]},
  //{path: 'create-workshop/:workshopID', component: NewWorkshopComponent},
  //{path: 'workshop', component: WorkshopsComponent},
  {path: 'bulk-load', component: BulkLoadComponent,canActivate: [AuthGuardService]},
  //POR LO PRONTO USAR EL PATH VACIO
  {
    path: 'home',
    loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
    data: { title: 'Login' }
  },
  {
    path: 'workshops',
    loadChildren: () => import('./views/workshops/workshops.module').then(m => m.WorkshopModule),
    data: { title: 'Work Shops' }
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./views/users/users.module').then(m => m.UsuariosModule),
    data: { title: 'Usuarios' }
  },
  {
    path: 'emails',
    loadChildren: () => import('./views/emails/emails.module').then(m => m.EmailsModule),
    data: { title: 'Emails' }
  },
  {path:'market',component: MarketComponent,canActivate: [AuthGuardService]},
  {path:'play/board/:workshopID/view/simplebalancesheet', component: RecordBalanceSheetComponent},
  {path:'play/board/:workshopID/:cycles/view/RecordIncomeAndBalance', component: RecordIncomeAndBalanceComponent},
  {path:'play/board/:workshopID/view/:teamId', component: BoardImageComponent},
  {path:'play/boardservices/:workshopID/view/:teamId', component: BoardImageServiceComponent},
  {path:'', pathMatch:'full', redirectTo:'home' },

];

/*@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}*/

export const routingModule = RouterModule.forRoot(routes);
